import type { FC } from 'react';
import React, { useState } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { N70, N500 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { Box, xcss } from '@atlaskit/primitives';

import { GridIcon, ListIcon, CompactIcon } from '@confluence/icons/entry-points/EntityViewIcons';

import { IconButton } from './Items/PresentationalComponents';
import type { PageCardAppearance } from './card-types';

const toggleContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	height: '24px',
	gap: 'space.050',
});

const toggleItemStyles = xcss({
	listStyle: 'none',
});

const i18n = defineMessages({
	cardView: {
		id: 'page-card.toggle.card-view',
		defaultMessage: 'Cards',
		description: 'Text for button that switches page card view to grid (card) view',
	},
	listView: {
		id: 'page-card.toggle.list-view',
		defaultMessage: 'List',
		description: 'Text for button that switches page card view to list view',
	},
	compactListView: {
		id: 'page-card.toggle.compact-list-view',
		defaultMessage: 'Compact list',
		description: 'Text for button that switches page card view to compact list view',
	},
});

type ToggleButtonProps = {
	isSelected: boolean;
	disabled?: boolean;
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const ToggleButton = styled(IconButton)`
  color: ${(props: ToggleButtonProps) =>
		!props.disabled && props.isSelected
			? token('color.text.selected', N500)
			: token('color.text.subtlest', N70)};
  border-radius: ${token('border.radius.100', '4px')};
  ${(props: ToggleButtonProps) => (props.disabled ? 'cursor: not-allowed' : '')};
  &:hover {
    background-color: ${(props: ToggleButtonProps) =>
			!props.disabled && !props.isSelected
				? token('color.background.accent.gray.subtlest', '#f1f2f4')
				: 'none'};
  }
}
`;

type PageCardAppearanceToggle = {
	appearance: PageCardAppearance;
	setAppearance: (PageCardAppearance) => void;
	disabled?: boolean;
	order?: PageCardAppearance[];
	source?: string;
};

export const PageCardAppearanceToggle: FC<PageCardAppearanceToggle> = ({
	appearance,
	setAppearance,
	disabled,
	order = ['grid', 'list', 'compact-list'],
	source,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();

	const onClick = (appearance: PageCardAppearance) => {
		if (disabled) {
			return;
		}

		setAppearance(appearance);

		const actionSubjectId = appearance === 'grid' ? 'cards' : 'list';
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source,
				action: 'clicked',
				actionSubject: 'tab',
				actionSubjectId,
			},
		}).fire();
	};

	const toggles: {
		[key in PageCardAppearance]: {
			id: PageCardAppearance;
			i18n: MessageDescriptor;
			icon: React.ElementType;
		} | null;
	} = {
		grid: {
			id: 'grid',
			i18n: i18n.cardView,
			icon: GridIcon,
		},
		list: {
			id: 'list',
			i18n: i18n.listView,
			icon: ListIcon,
		},
		'compact-list': {
			id: 'compact-list',
			i18n: i18n.compactListView,
			icon: CompactIcon,
		},
		'popup-view': null,
		'end-of-page': null,
	};

	return (
		<Box xcss={toggleContainerStyles} role="list">
			{order.map((t) => {
				const toggle = toggles[t];
				const ToggleIcon = toggle?.icon;
				return (
					toggle &&
					ToggleIcon && (
						<Box xcss={toggleItemStyles} role="listitem">
							<Tooltip
								key={toggle.id}
								content={intl.formatMessage(toggle.i18n)}
								hideTooltipOnClick
								position="top"
							>
								<ToggleButton
									isSelected={appearance === toggle.id}
									onClick={() => onClick(toggle.id)}
									aria-label={intl.formatMessage(toggle.i18n)}
									aria-pressed={appearance === toggle.id}
									disabled={disabled}
								>
									<ToggleIcon
										label=""
										size="medium"
										primaryColor={
											!disabled && appearance === toggle.id
												? token('color.text.selected', N500)
												: token('color.text.subtlest', N70)
										}
									/>
								</ToggleButton>
							</Tooltip>
						</Box>
					)
				);
			})}
		</Box>
	);
};

export const useAppearanceToggle = (defaultAppearance: PageCardAppearance = 'grid') => {
	const [appearance, setAppearance] = useState(defaultAppearance);
	return { appearance, setAppearance };
};
