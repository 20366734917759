import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const compact24Svg = () => (
	<svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			width="24"
			height="24"
			transform="translate(0.5)"
			fillOpacity="0"
			fill={token('elevation.surface', '#FFFFFF')}
		/>
		<path
			d="M19.5 17C20.0523 17 20.5 17.4477 20.5 18C20.5 18.5128 20.114 18.9355 19.6166 18.9933L19.5 19H5.5C4.94771 19 4.5 18.5523 4.5 18C4.5 17.4872 4.88604 17.0645 5.38338 17.0067L5.5 17H19.5ZM19.5 13C20.0523 13 20.5 13.4477 20.5 14C20.5 14.5128 20.114 14.9355 19.6166 14.9933L19.5 15H5.5C4.94771 15 4.5 14.5523 4.5 14C4.5 13.4872 4.88604 13.0645 5.38338 13.0067L5.5 13H19.5ZM19.5 9C20.0523 9 20.5 9.44771 20.5 10C20.5 10.5128 20.114 10.9355 19.6166 10.9933L19.5 11H5.5C4.94771 11 4.5 10.5523 4.5 10C4.5 9.48716 4.88604 9.06448 5.38338 9.00671L5.5 9H19.5ZM19.5 5C20.0523 5 20.5 5.44771 20.5 6C20.5 6.51284 20.114 6.93552 19.6166 6.99329L19.5 7H5.5C4.94771 7 4.5 6.55229 4.5 6C4.5 5.48716 4.88604 5.06448 5.38338 5.00671L5.5 5H19.5Z"
			fill="currentColor"
		/>
	</svg>
);

export const Compact24Icon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.icon', '#44546F');
	const label = props.label || '';
	return <Icon glyph={compact24Svg} {...props} primaryColor={primaryColor} label={label} />;
};
